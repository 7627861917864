import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import pageSettings from '../config/page-settings';
import {ServicesProvider} from '../provider/services';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import {NgxPermissionsService} from 'ngx-permissions';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  pageSettings = pageSettings;
  alertLogin = false;
  public loginForm: FormGroup;
  year = moment().format('YYYY');
  position: number;

  constructor(private router: Router,
              private _services: ServicesProvider,
              private formBuilder: FormBuilder,
              private permissionsService: NgxPermissionsService) {

    this.pageSettings.pageEmpty = true;
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.router.navigate(['create-work-order']);
    }
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }

  formSubmit() {
    if (this.loginForm.valid) {
      const body = this.loginForm.value;
      this._services.loginByEmail(body).subscribe(res => {
        Swal.fire({
          icon: 'success',
          title: 'Login success.',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', JSON.stringify(res.user));
          localStorage.setItem('user_email', res.user.email);
          localStorage.setItem('permission', JSON.stringify(res.permissions));
          const perm = JSON.parse(localStorage.getItem('permission'));
          this.permissionsService.loadPermissions(perm);
          const permission_arr = res.permissions;
          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_create_workorder') {
              this.router.navigate(['create-work-order']);
              return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_request') {
              this.router.navigate(['work-order-request']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_cancel') {
              this.router.navigate(['work-order-cancel']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_reject') {
              this.router.navigate(['work-order-reject']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_approve') {
              this.router.navigate(['work-order-approve']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_postpone') {
              this.router.navigate(['work-order-postpone']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_inprogress') {
              this.router.navigate(['create-work-in-progress']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_hold_progress') {
              this.router.navigate(['work-order-hold-progress']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_wait_approve') {
              this.router.navigate(['work-order-wait-approve']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_work_order_complete') {
              this.router.navigate(['work-order-complete']);
               return false;
            }
          }

          for (let i = 0; i < permission_arr.length; i++) {
            if (permission_arr[i] === 'pm_planning_scheduling_list') {
              this.router.navigate(['planning-scheduling-list']);
               return false;
            }
          }
        });
      }, (err) => {
        if (err.status === 400) {
          Swal.fire('Error', 'ขออภัย ท่านระบุ Email และ Password ไม่ถูกต้อง', 'error');
        } else {
          console.log(err);
        }
      });
    }
  }


}
