import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {saveAs} from 'file-saver';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class ServicesProvider {
  // api_url = 'http://192.168.1.99:9000/api/v1'; // prods
  // api_url = 'http://localhost:3000/api'; // local
  api_url = window.location.origin + '/api';

  constructor(private httpClient: HttpClient) {
  }

  loginByEmail(body): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    return this.httpClient.post(this.api_url + '/login-by-email', body, {headers});
  }

  logout(): Observable<any> {
    const body = {};
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/logout', body, {headers});
  }

  public getPosition() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get<any>(`${this.api_url}/position`, {headers});
  }

  public postPosition(body) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post<any>(`${this.api_url}/position`, body, {headers});
  }

  public postPositionById(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post<any>(`${this.api_url}/position/` + id, body, {headers});
  }

  public deletePositionById(id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete<any>(`${this.api_url}/position/` + id, {headers});
  }

  public getDepartment() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get<any>(`${this.api_url}/department`, {headers});
  }

  public departmentListDetail() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get<any>(`${this.api_url}/department-list`, {headers});
  }

  public departmentListDetailById(id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get<any>(`${this.api_url}/department/` + id, {headers});
  }

  public postDepartmentUpdate(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post<any>(`${this.api_url}/department/` + id, body, {headers});
  }

  public postDepartment(body) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post<any>(`${this.api_url}/department`, body, {headers});
  }

  public deleteDepartment(id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete<any>(`${this.api_url}/department/` + id, {headers});
  }

  public getUsers() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get<any>(`${this.api_url}/users`, {headers});
  }

  public getUsersByID(id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get<any>(`${this.api_url}/users/` + id, {headers});
  }

  public createUser(body) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(`${this.api_url}/users`, body, {headers});
  }

  public usersUpdate(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(`${this.api_url}/users/` + id, body, {headers});
  }

  public usersChangPassword(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(`${this.api_url}/users-chang-pass/` + id, body, {headers});
  }

  public deleteUser(id) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(`${this.api_url}/users/` + id, {headers});
  }

  getRoles() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };

    return this.httpClient.get<any>(`${this.api_url}/roles`, {headers});
  }

  createRoles(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(`${this.api_url}/roles`, body, {headers});


  }

  editRoles(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.patch(`${this.api_url}/roles/` + id, body, {headers});
  }

  deleteRoles(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };

    return this.httpClient.delete(`${this.api_url}/roles/` + id, {headers});
  }

  getRoleHasPermission(role_id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/role-has-permission/` + role_id, {headers});
  }

  getPermission() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/permissions`, {headers});
  }

  getMstPriority() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/mst-list-cwo`, {headers});
  }

  getMstList() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/mst-list-cpn-sdl`, {headers});
  }


  postWorkOrder(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(`${this.api_url}/pm/work-order`, body, {headers});
  }

  putWorkOrder(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.put(`${this.api_url}/pm/work-order/` + id, body, {headers});
  }

  getWorkOrderList(code, dt, action_type = '') {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/work-order-list/` + code + '/' + dt + '?action_type=' + action_type, {headers});
  }

  getWorkOrderListCalendar(dt_start, dt_end) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/work-order-list-calendar/` + dt_start + '/' + dt_end, {headers});
  }

  getWorkOrderById(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/work-order/` + id, {headers});
  }

  postWorkOrderState(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(`${this.api_url}/pm/work-order-state`, body, {headers});
  }

  // PM Repair Code
  getRepairCode() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/repair-code', {headers});
  }

  createRepairCode(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/pm/repair-code/', body, {headers});
  }

  editRepairCode(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.patch(this.api_url + '/pm/repair-code/' + id, body, {headers});
  }

  removeRepairCode(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(this.api_url + '/pm/repair-code/' + id, {headers});
  }

  // PM Repair Item List
  getRepairItemList() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/repair-item-list', {headers});
  }

  createRepairItemList(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/pm/repair-item-list/', body, {headers});
  }

  editRepairItemList(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.patch(this.api_url + '/pm/repair-item-list/' + id, body, {headers});
  }

  removeRepairItemList(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(this.api_url + '/pm/repair-item-list/' + id, {headers});
  }

  // PM Cause
  getCause() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/cause', {headers});
  }

  createCause(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/pm/cause/', body, {headers});
  }

  editCause(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.patch(this.api_url + '/pm/cause/' + id, body, {headers});
  }

  removeCause(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(this.api_url + '/pm/cause/' + id, {headers});
  }

  // PM Machine Code
  getMachineCode() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/machine-code', {headers});
  }

  createMachineCode(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/pm/machine-code', body, {headers});
  }

  editMachineCode(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.patch(this.api_url + '/pm/machine-code/' + id, body, {headers});
  }

  removeMachineCode(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(this.api_url + '/pm/machine-code/' + id, {headers});
  }

  // PM Machine Group
  getMachineGroup() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/machine-group', {headers});
  }

  createMachineGroup(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/pm/machine-group/', body, {headers});
  }

  editMachineGroup(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.patch(this.api_url + '/pm/machine-group/' + id, body, {headers});
  }

  removeMachineGroup(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(this.api_url + '/pm/machine-group/' + id, {headers});
  }

  // PM Machine System
  getMachineSystem() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/machine-system', {headers});
  }

  createMachineSystem(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/pm/machine-system', body, {headers});
  }

  editMachineSystem(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.patch(this.api_url + '/pm/machine-system/' + id, body, {headers});
  }

  removeMachineSystem(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(this.api_url + '/pm/machine-system/' + id, {headers});
  }

  // PM Machine Symptom
  getMachineSymptom() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/machine-symptom', {headers});
  }

  createMachineSymptom(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/pm/machine-symptom', body, {headers});
  }

  editMachineSymptom(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.patch(this.api_url + '/pm/machine-symptom/' + id, body, {headers});
  }

  removeMachineSymptom(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(this.api_url + '/pm/machine-symptom/' + id, {headers});
  }

  createPlanning(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(this.api_url + '/pm/planning-scheduling', body, {headers});
  }

  postPlanningExportExcel(body) {
    this.httpClient.post(this.api_url + '/pm/planning-scheduling-export-excel', body, {
      responseType: 'arraybuffer', headers: {
        'Content-Type': 'application/json'
      }
    }).subscribe(response => {
      this.downLoadFile(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    });
  }

  downLoadFile(data: any, type: string) {
    const name_file = 'planning-scheduling-' + moment().format('YYYYMMDDHHmmss') + '.xlsx';
    const blob = new Blob([data], {type: type});
    if (blob.size > 100) {
      saveAs(blob, name_file);
    } else {
      alert('error');
    }
  }


  putPlanning(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.put(this.api_url + '/pm/planning-scheduling/' + id, body, {headers});
  }

  getPlanning(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/planning-scheduling/' + id, {headers});
  }

  getPlanningListDtNow(dt) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(this.api_url + '/pm/planning-scheduling-list-dt-now/' + dt, {headers});
  }

  getPlanningSchedulingListCalendar(dt_start, dt_end) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/planning-scheduling-calendar/` + dt_start + '/' + dt_end, {headers});
  }

  getWorkOrderByOrderCalendar(order) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/work-order-by-order/` + order, {headers});
  }

  getPlanningSchedulingByOrderCalendar(order) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/planning-scheduling-list-by-order/` + order, {headers});
  }

  getResourceModel() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.get(`${this.api_url}/pm/resource`, {headers});
  }

  createResourceModel(body) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(`${this.api_url}/pm/resource`, body, {headers});
  }

  editResourceModel(body, id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.post(`${this.api_url}/pm/resource/` + id, body, {headers});
  }

  deleteResourceModel(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    };
    return this.httpClient.delete(`${this.api_url}/pm/resource/` + id, {headers});
  }
}
