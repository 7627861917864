<!-- begin login -->
<div id="bg-login" class="login login-with-news-feed">
    <div class="login-content">
      <div style="width: 100%; margin: auto; text-align: center">
      <img src="assets/img/scg-logo-red.png" class="scg-logo" width="360">
      </div>
      <!-- begin nav-tabs -->
      <ngb-tabset>

        <ngb-tab>
          <ng-template ngbTabTitle>
            <span class="d-sm-none">Login with email</span>
            <span class="d-sm-block d-none">Login with email</span>
          </ng-template>
          <ng-template ngbTabContent>
            <form [formGroup]="loginForm" (ngSubmit)="formSubmit()" class="margin-bottom-0">
              <div class="form-group m-b-15">
                <label>Email</label>
                <input type="text" class="form-control form-control-lg" placeholder="Email" formControlName="email" required/>
              </div>
              <div class="form-group m-b-15">
                <label>Password</label>
                <input type="password" class="form-control form-control-lg" placeholder="PASSWORD" formControlName="password" required/>
              </div>
              <div class="checkbox checkbox-css m-b-30">
                <input type="checkbox" id="remember_me_checkbox" value="" />
                <label for="remember_me_checkbox">Remember Me</label>
              </div>
              <div class="login-buttons">
                <button type="submit" [disabled]="!loginForm.valid" class="btn btn-success btn-block btn-lg">Sign me in</button>
              </div>
            </form>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
      <!-- end nav-tabs -->

      <hr/>
      <p class="text-center text-grey-darker mb-0">
        &copy; All Right Reserved {{year}}
      </p>
    </div>
</div>
<!-- end login -->
