<!-- begin #header -->
<div id="header" class="header navbar-default"
     style="background: #ED1C24!important;font-size: 16px;color: white!important;">
  <!-- begin navbar-header -->
  <div class="navbar-header">

    <div id="_dt">
      <i class="far fa-lg fa-fw m-r-10 fa-calendar-alt"></i>{{date}}
      |
      <ul class="navbar-nav" style="display: inline-flex">
        <li class="dropdown" ngbDropdown style="margin: 0; padding: 0; border: 0;">
          <a href="javascript:;" class="dropdown-toggle" [ngClass]="active_admin_class" ngbDropdownToggle>
            <i class="fa fa-user"></i>
            {{(user_info) ? user_info['fname'] : ''}}
            <b class="caret ml-2 ml-md-0"></b>
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a (click)="openChangPass(modalPassword)" style="cursor: pointer;">
              <i class="fas fa-lg fa-fw fa-key mr-2"></i>
              Change password
            </a>
            <div class="dropdown-divider"></div>
            <a (click)="logOut()" style="cursor: pointer;">
              <i class="fas fa-lg fa-fw fa-lock mr-2"></i> Logout
            </a>
          </div>
        </li>
      </ul>

      | <i class="far fa-lg fa-fw fa-registered"></i>
      {{(user_info) ? user_info['role'] : ''}}
    </div>
    <!-- example 1 - using absolute position for center -->
    <nav class="navbar navbar-expand-md navbar-dark">
      <a href="/#/dashboard" class="mr-2">
        <img [src]="logo" [ngClass]="logo_size">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar"
              (click)="toggleMenu('besics')">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse" id="collapsingNavbar" *ngIf="dropdowns['besics']">
        <ul class="navbar-nav mr-auto">

          <li class="nav-item" *ngxPermissionsOnly="['superadmin','operator','pm_create_workorder']">
            <a class="nav-link" routerLink="/create-work-order" routerLinkActive="active"
               (click)="toggleMenu('besics')">
              <i class="fa fa-bell"></i>
              Add Work order
            </a>
          </li>
          <li class="dropdown" ngbDropdown *ngxPermissionsOnly="['pm_create_workorder','pm_work_order_request','pm_work_order_cancel',
          'pm_work_order_reject','pm_work_order_approve','pm_work_order_postpone',
'pm_work_order_inprogress','pm_work_order_hold_progress','pm_work_order_wait_approve',
'pm_work_order_complete','pm_work_order_calendar','pm_planning_scheduling_list',
'pm_planning_scheduling_calendar']">
            <a href="javascript:;" class="dropdown-toggle" [ngClass]="active_maintenance_class" ngbDropdownToggle>
              <i class="fa fa-user"></i>
              <span class="mr-2">Maintenance</span>
              <b class="caret ml-2 ml-md-0"></b>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <a *ngxPermissionsOnly="['pm_work_order_request']" class="dropdown-item" style="color: black"
                 routerLink="/work-order-request" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Request
              </a>
              <div *ngxPermissionsOnly="['pm_work_order_cancel']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_cancel']" class="dropdown-item" style="color: black"
                 routerLink="/work-order-cancel" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Cancel
              </a>
              
              <div *ngxPermissionsOnly="['pm_work_order_approve']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_approve']" class="dropdown-item" style="color: black" routerLink="/work-order-approve" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Approve
              </a>
              <div *ngxPermissionsOnly="['pm_work_order_postpone']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_postpone']" class="dropdown-item" style="color: black" routerLink="/work-order-postpone" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Postpone
              </a>
              <div *ngxPermissionsOnly="['pm_work_order_inprogress']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_inprogress']" class="dropdown-item" style="color: black" routerLink="/work-order-in-progress"
                 routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order In Progress
              </a>
              <div *ngxPermissionsOnly="['pm_work_order_hold_progress']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_hold_progress']" class="dropdown-item" style="color: black" routerLink="/work-order-hold-progress"
                 routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Hold Progress
              </a>
              <div *ngxPermissionsOnly="['pm_work_order_wait_approve']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_wait_approve']" class="dropdown-item" style="color: black" routerLink="/work-order-wait-approve"
                 routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Wait Approve
              </a>
              <div *ngxPermissionsOnly="['pm_work_order_complete']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_complete']" class="dropdown-item" style="color: black" routerLink="/work-order-complete" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Complete
              </a>
              <div *ngxPermissionsOnly="['pm_work_order_reject']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_reject']" class="dropdown-item" style="color: black"
                 routerLink="/work-order-reject" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Reject
              </a>
              <div *ngxPermissionsOnly="['pm_work_order_calendar']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_work_order_calendar']" class="dropdown-item" style="color: black" routerLink="/work-order-calendar" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Order Calendar
              </a>
              <div *ngxPermissionsOnly="['pm_planning_scheduling_list']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_planning_scheduling_list']" class="dropdown-item" style="color: black" routerLink="/planning-scheduling-list"
                 routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Planning & Scheduling List
              </a>
              <div *ngxPermissionsOnly="['pm_planning_scheduling_calendar']" class="dropdown-divider"></div>
              <a *ngxPermissionsOnly="['pm_planning_scheduling_calendar']" class="dropdown-item" style="color: black" routerLink="/planning-scheduling-calendar"
                 routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Planning Scheduling Calendar
              </a>

            </div>
          </li>
          <li class="dropdown" ngbDropdown *ngxPermissionsOnly="['user_management']">
            <a href="javascript:;" class="dropdown-toggle" [ngClass]="active_admin_class" ngbDropdownToggle>
              <i class="fa fa-user"></i>
              <span class="mr-2">Admin</span>
              <b class="caret ml-2 ml-md-0"></b>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <a class="dropdown-item" style="color: black" routerLink="/user-management/users"
                 routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-users mr-3"></i>
                User Management
              </a>
            </div>
          </li>
          <li class="dropdown" ngbDropdown *ngxPermissionsOnly="['pm_setting']">
            <a href="javascript:;" class="dropdown-toggle" [ngClass]="active_setting_class" ngbDropdownToggle>
              <i class="fa fa-cog"></i>
              <span class="mr-2">Setting</span>
              <b class="caret ml-2 ml-md-0"></b>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/resource-model" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-cog mr-3"></i>
                Resource Model
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/machine-code" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-cog mr-3"></i>
                Machine Code
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/machine-group" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-cog mr-3"></i>
                Machine Group
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/machine-system" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-cog mr-3"></i>
                Machine System
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/machine-symptom" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-cog mr-3"></i>
                Machine Symptom
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/repair-code" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-cog mr-3"></i>
                Repair Code
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/repair-item-list" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-cog mr-3"></i>
                Repair Item List
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/cause" routerLinkActive="active"
                 (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-cog mr-3"></i>
                Cause
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>

  </div>
</div>

<ng-template #modalPassword let-c="close" let-d="dismiss">
  <form [formGroup]="loginForm" (ngSubmit)="changPassword()">
    <div class="modal-header">
      <h4 class="modal-title">Change password user : {{ user_item.fname }} {{ user_item.lname }}</h4>
      <i class="bx bx-x fa-lg" (click)="c('Close click')"></i>
    </div>
    <div class="modal-body row f-s-16">

      <div class="form-group col-sm-12">
        <label class="col-sm-12 col-form-label">New Password</label>
        <div class="col-sm-12 input-group">
          <input [type]="repeatFieldTextType ? 'text' : 'password'" class="form-control"
                 formControlName="input_password"
                 [ngClass]="{'is-invalid': form.input_password.errors}">
          <div class="input-group-append">
          <span class="input-group-text">
           <i class="fa" [ngClass]="{ 'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType }"
              (click)="toggleRepeatFieldTextType()"></i>
          </span>
          </div>
          <div *ngIf="form.input_password.errors" class="invalid-feedback">
            <span>The password must be at least 8 characters and contain at least one uppercase character.</span>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="c('Close click')">cancel</button>
      <button type="submit" class="btn btn-success">Save</button>
    </div>
  </form>
</ng-template>
