import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './auth/auth.guard';
// Home
import {LoginComponent} from './login/login.component';
import {Error404Page} from './pages/error-404/error-404';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, data: {title: 'Login'}},
  {
    path: 'user-management',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./pages/user-management/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./pages/user-management/roles/roles.module').then(m => m.RolesModule)
      },
      {
        path: 'department',
        loadChildren: () => import('./pages/user-management/department/department.module').then(m => m.DepartmentModule)
      },
      {
        path: 'position',
        loadChildren: () => import('./pages/user-management/position/position.module').then(m => m.PositionModule)
      },
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create-work-order',
        loadChildren: () => import('./pm/create-work-order/create-work-order.module')
          .then(m => m.CreateWorkOrderModule)
      },
      {
        path: 'work-order-list',
        loadChildren: () => import('./pm/work-order-list/work-order-list.module')
          .then(m => m.WorkOrderListModule)
      },
      {
        path: 'work-order-request',
        loadChildren: () => import('./pm/work-order-request/work-order-request.module')
          .then(m => m.WorkOrderRequestModule)
      },
      {
        path: 'work-order-details',
        loadChildren: () => import('./pm/work-order-details/work-order-details.module')
          .then(m => m.WorkOrderDetailsModule)
      },

      {
        path: 'work-order-approve',
        loadChildren: () => import('./pm/work-order-approve/work-order-approve.module')
          .then(m => m.WorkOrderApproveModule)
      },
      {
        path: 'work-order-postpone',
        loadChildren: () => import('./pm/work-order-postpone/work-order-postpone.module')
          .then(m => m.WorkOrderPostponeModule)
      },
      {
        path: 'work-order-in-progress',
        loadChildren: () => import('./pm/work-order-in-progress/work-order-in-progress.module')
          .then(m => m.WorkOrderInProgressModule)
      },
      {
        path: 'work-order-hold-progress',
        loadChildren: () => import('./pm/work-order-hold-progress/work-order-hold-progress.module')
          .then(m => m.WorkOrderHoldProgressModule)
      },
      {
        path: 'work-order-wait-approve',
        loadChildren: () => import('./pm/work-order-wait-approve/work-order-wait-approve.module')
          .then(m => m.WorkOrderWaitApproveModule)
      },
      {
        path: 'work-order-complete',
        loadChildren: () => import('./pm/work-order-complete/work-order-complete.module')
          .then(m => m.WorkOrderCompleteModule)
      },
      {
        path: 'work-order-reject',
        loadChildren: () => import('./pm/work-order-reject/work-order-reject.module')
          .then(m => m.WorkOrderRejectModule)
      },
      {
        path: 'work-order-cancel',
        loadChildren: () => import('./pm/work-order-cancel/work-order-cancel.module')
          .then(m => m.WorkOrderCancelModule)
      },
      {
        path: 'create-planning-scheduling/:id',
        loadChildren: () => import('./pm/create-planning-scheduling/create-planning-scheduling.module')
          .then(m => m.CreatePlanningSchedulingModule)
      },
      {
        path: 'planning-scheduling-list',
        loadChildren: () => import('./pm/planning-scheduling-list/planning-scheduling-list.module')
          .then(m => m.PlanningSchedulingListModule)
      },
      {
        path: 'machine-code',
        loadChildren: () => import('./pm/machine-code/machine-code.module')
          .then(m => m.MachineCodeModule)
      },
      {
        path: 'machine-group',
        loadChildren: () => import('./pm/machine-group/machine-group.module')
          .then(m => m.MachineGroupModule)
      },
      {
        path: 'machine-system',
        loadChildren: () => import('./pm/machine-system/machine-system.module')
          .then(m => m.MachineSystemModule)
      },
      {
        path: 'machine-symptom',
        loadChildren: () => import('./pm/machine-symptom/machine-symptom.module')
          .then(m => m.MachineSymptomModule)
      },
      {
        path: 'repair-code',
        loadChildren: () => import('./pm/repair-code/repair-code.module')
          .then(m => m.RepairCodeModule)
      },
      {
        path: 'repair-item-list',
        loadChildren: () => import('./pm/repair-item-list/repair-item-list.module')
          .then(m => m.RepairItemListModule)
      },
      {
        path: 'cause',
        loadChildren: () => import('./pm/cause/cause.module')
          .then(m => m.CauseModule)
      },
      {
        path: 'work-order-calendar',
        loadChildren: () => import('./pm/work-order-calendar/work-order-calendar.module')
          .then(m => m.WorkOrderCalendarModule)
      },
      {
        path: 'planning-scheduling-calendar',
        loadChildren: () => import('./pm/planning-scheduling-calendar/planning-scheduling-calendar.module')
          .then(m => m.PlanningSchedulingCalendarModule)
      },
      {
        path: 'resource-model',
        loadChildren: () => import('./pm/resource-model/resource-model.module')
          .then(m => m.ResourceModelModule)
      },
    ]
  },
  {path: '404', component: Error404Page},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule {
}
